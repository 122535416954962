import * as React from 'react';

import {
  HeadMeta,
  IInfoLink,
  IMenuLink,
  ITileProps,
  Layout,
  PreFooter,
  SmallHero,
} from '../../components';
import { LanguageContext } from '../../context/LanguageContext';
import { IContentfulVacationBanner } from '../../utils/types';

import * as styles from './PrivacyAndToSTemplate.module.less';

interface IPrivacyAndToSComponentProps {
  menuLinks: IMenuLink[];
  infoLinks: IInfoLink[];
  title: string;
  slug: string;
  html?: string;
  prefooterItems?: ITileProps[];
  vacationBanner?: IContentfulVacationBanner;
  language: 'De' | 'En';
}

export const PrivacyAndToSComponent: React.FunctionComponent<IPrivacyAndToSComponentProps> = (
  props
) => {
  return (
    <LanguageContext.Provider value={props.language}>
      <Layout
        links={props.menuLinks}
        infoLinks={props.infoLinks}
        vacationBanner={props.vacationBanner}
      >
        <HeadMeta title={props.title} lang={props.language.toLowerCase()} />
        <SmallHero
          title={props.title}
          featuredImage={undefined}
          location={{ pathname: props.slug }}
        />
        {props.html && (
          <div
            data-testid="htmlContainer"
            className={styles.contentWrapper}
            dangerouslySetInnerHTML={{ __html: props.html }}
          />
        )}
        {props.prefooterItems && <PreFooter items={props.prefooterItems} />}
      </Layout>
    </LanguageContext.Provider>
  );
};
