import { graphql } from 'gatsby';
import * as React from 'react';

import { formatInfoLinks, formatMenuLinks } from '../../utils/helpers';
import { IPrivacyAndToSProps } from '../../utils/types';

import { PrivacyAndToSComponent } from './PrivacyAndToSComponent';

const PrivacyAndToSTemplate = ({
  data,
}: IPrivacyAndToSProps): React.ReactElement => {
  const {
    title,
    slug,
    language,
    pageContent: {
      childMarkdownRemark: { html },
    },
    preFooter,
  } = data.contentfulPrivacyAndToS;

  const menuLink = formatMenuLinks(data.allContentfulCategory.nodes, language);
  const infoLink = formatInfoLinks(
    data.allContentfulPrivacyAndToS.nodes,
    language
  );

  return (
    <PrivacyAndToSComponent
      menuLinks={menuLink}
      infoLinks={infoLink}
      title={title}
      slug={slug}
      html={html}
      prefooterItems={preFooter?.items}
      language={language}
      vacationBanner={data.contentfulVacationBanner}
    />
  );
};

export const query = graphql`
  query PrivacyAndTosQuery($slug: String, $language: String) {
    allContentfulCategory(
      sort: { fields: order }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        ...category
      }
    }
    contentfulPrivacyAndToS(slug: { eq: $slug }) {
      slug
      title
      language
      pageContent {
        childMarkdownRemark {
          html
        }
      }
      preFooter {
        ...preFooterBlock
      }
    }
    allContentfulPrivacyAndToS(filter: { language: { eq: $language } }) {
      nodes {
        title
        language
        url: slug
      }
    }
    contentfulVacationBanner {
      ...vacationBanner
    }
  }
`;

export default PrivacyAndToSTemplate;

